export default {
  signIn: "登录",
  userError: "请输入用户名",
  userPlaceholder: "请输入用户名",
  PWError: "请输入密码",
  passPlaceholder: "请输入密码",
  PWSubError: "密码必须是3位以上的字母或者数字",
  captchaError: "请点击验证码进行校验",
  signInSuccess: "登录成功",
  Submit: "提交",
  Cacel: "取消",
  Reset: "重置",
};
