import login from "./en/login";
import layout from "./en/layout";
import user from "./en/user";
import flight from "./en/flight";
import airconf from "./en/airconf";

export default {
  ...login,
  ...layout,
  ...user,
  ...flight,
  ...airconf,
};
