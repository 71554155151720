export default{
  // 航班信息
  AirCode: "航司",
  AirLine: "航线",
  AirDep: "起飞地点",
  AirLand: "降落地点",
  AirCreateTime: "创建日期",
  AirExpireTime: "过期时间",
  AirExpireTimeSecond: "过期时间(秒)",
  AirDanger: "危险",
  AirInfo: "暂时不用担心",
  AirNormal: "正常",
  AirWarning: "警告",
  AirDetail: "详情",
  AirPlaceholder: "输入航线查询",
  // 航班详情
  depAirport: "起飞机场",
  depTime: "起飞时间",
  arrAirport: "降落机场",
  arrTime: "降落时间",
  duration: "航班时长",
  layoverTime: "经停/中转时长",
  stopCities: "经停/中转城市",
  stops: "经停/中转次数",
  // 
  flightInfo: "航班信息",
  actualCarrier: "航司代码",
  actualFlightNo: "航班号",
  aircraftCode: "机型",
  availSeatNum: "剩余座位数",
  cabin: "实际仓位",
  cabinGrade: "仓等(经济舱)",
  fareBasis: "飞行规则",
  adultWeight: "免费成人托运行李重量",
  adultPiece: "免费成人托运行李件数",
  childWeight: "免费儿童托运行李重量",
  childPiece: "免费儿童托运行李件数",
  // 
  priceInfo: "价格详情",
  adultPrice: "成人票价",
  realAdultPrice: "对外展示成人票价",
  childPrice: "儿童票价",
  realChildPrice: "对外展示儿童票价",
  adultTax: "成人税费",
  realAdultTax: "对外展示成人税费",
  childTax: "儿童税费",
  realChildTax: "对外展示儿童税费",
  currency: "币种",
  refresh: "刷新",
  // 机场信息
  AirportInfo: "机场信息",
  airportCode: "机场编码",
  airportName: "机场名称",
  airCountry: "国家",
  airCountryCode: "国家代码",
  airCity: "城市",
  // 航司信息
  airCarriersInfo: "航司信息",
  carriersName: "航司名字",
  carriersCode: "航司编码",
  // 热门航线
  hotlineInfo: "热门航线",
  hotLineAdd: "添加新热门航线",
  fromCode: "出发机场编码",
  toCode: "到达机场编码",
  isOnline: "是否在线",
  hotLineDelete: "删除",
  Create: "创建",
  Cancel: "取消",
}
