export default{
  // 个人信息
  UserAccount: "账号",
  UserPhone: "电话号码",
  UserEmail: "邮件",
  UserPasswd: "密码",
  UserCreateBy: "创建人",
  UserCreateTime: "创建时间",
  UserCity: "城市",
  UserTag: "标签",
  UserAddr: "地址",
  UserDesc: "描述",
  UserOldPwd: "旧密码",
  UserNewPwd: "新密码",
  UserEditInfo: "编辑用户个人信息",
  UserResetPwd: "重置密码",
  DescPlaceholder: "简单介绍一下自己吧...",
  // 子用户账号
  ChildAccount: "账号",
  ChildPhone: "电话号码",
  ChildNickname: "昵称",
  ChildEmail: "邮件",
  ChildCreateTime: "创建时间",
  ChildDisable: "是否禁用",
  ChildEdit: "编辑",
  ChildDelete: "删除",
  ChildAdd: "新增",
  ChildAddTitle: "新增子账户",
  ChildPwd: "密码",
  ChildConfirm: "确认密码",
  ChildReset: "重置密码",
  ChildResetTitle: "重置子账户密码",
}