export default{
  Delete: "Delete",
  Create: "Create",
  Cancel: "Cancel",
  Add: 'Add',
  Refresh: 'Refresh',
  // 航班配置相关
  ConfItem: "Setting Items",
  ConfAdd: "Add New Item",
  ConfKey: "Keys",
  ConfValue: "Values",
  ConfComment: "Comments",
  // 币种汇率相关
  CurrencyRateItems: "Currency Rate",
  RateAdd: "Add New Rate",
  RateFrom: "Currency Code",
  RateTo: "Dest Currency Code",
  RateFromName: "Currency Name",
  RateToName: "Dest Currency Name",
  Rate: "Rate",
  RateInverse: "Inverse Rate",
  // rules
  keyMsg: "Please input new config key",
  valMsg: "Please input new config value",
  commentMsg: "Please input new config comment",
  currencyFromNameMsg: "Please input currency name",
  currencyToNameMsg: "Please input need inverse dest currency name",
  currencyFromMsg: "Please input currency code",
  currencyToMsg: "Please input need inverse dest currency code",
  currencyRateMsg: "Please input rate",
  InverseRateMsg: "Please input inverse rate",
}