import axios from "axios";
import router from "@/router/index";
import useMessage from "@/hooks/useMessage";
import { refreshToken } from "@/api/user";

const { ElMessage } = useMessage();

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  // 请求超时时间
  timeout: 1000 * 60 * 5,
});

const refreshUserToken = async()=>{
  let res = await refreshToken()
  // console.log("刷新令牌", res.data);
  if(res.errno === "0"){
    localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, res.data.access_token);
  }
}

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // console.log("请求拦截器", config);
    // 在发送请求之前给 header 设置 token
    if (!config.url.includes("/auth")) {
      config.headers[process.env.VUE_APP_AJAX_HEADER_AUTH_NAME] = 'Bearer ' +
        localStorage.getItem(process.env.VUE_APP_TOKEN_NAME)|| "";
    };
    // refresh
    if(config.url.includes("/refresh")){
      config.headers[process.env.VUE_APP_AJAX_HEADER_AUTH_NAME] = 'Bearer ' +
        localStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN)|| "";
    };
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    console.log("响应拦截器", response);
    if (response.data.errno === '5001'|| response.data.errno === '5003' || response.data.errno === '4105') {
      ElMessage({
        showClose: true,
        message: response.data.errmsg,
        type: "error",
      });
      // 跳转到对应的页面
      router.push({ path: "/login" });
    }else if(response.data.errno === '5002'){
      // 令牌已经过期，刷新的token
      refreshUserToken()
    }else if(response.data.errno !== '0'){
      ElMessage({
        showClose: true,
        message: response.data.errmsg,
        type: "error",
      });
    }else{
      ElMessage({
        showClose: true,
        message: response.data.errmsg,
        type: "success",
      });
    }
    // 对响应数据做点什么
    // console.log(response.data);
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    // console.log('error', error);
    return Promise.reject(error);
  },
);

export default service;
