export default{
  // 个人信息
  UserAccount: "Account",
  UserPhone: "Phone",
  UserEmail: "Email",
  UserPasswd: "Password",
  UserCreateBy: "CreateBy",
  UserCreateTime: "CreateTime",
  UserCity: "City",
  UserTag: "Tag",
  UserAddr: "Address",
  UserDesc: "Description",
  UserOldPwd: "Old Password",
  UserNewPwd: "New Password",
  UserEditInfo: "Edit Profile Info",
  UserResetPwd: "Reset User Password",
  DescPlaceholder: "please make a brief introduction about yourself ...",
  // 子用户账号
  ChildAccount: "Account",
  ChildPhone: "Phone",
  ChildNickname: "Nickname",
  ChildEmail: "Email",
  ChildCreateTime: "CreateTime",
  ChildDisable: "Disable",
  ChildEdit: "Edit",
  ChildDelete: "Delete",
  ChildAdd: "Add",
  ChildAddTitle: "Add New Child Account",
  ChildPwd: "Password",
  ChildConfirm: "Confirm Password",
  ChildReset: "Reset Password",
  ChildResetTitle: "Reset Child Account Password",
}