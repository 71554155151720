export default{
  Delete: "删除",
  Create: "创建",
  Cancel: "取消",
  Add: '添加',
  Refresh: '刷新',
  // 航班配置相关
  ConfItem: "配置项",
  ConfAdd: "添加配置项",
  ConfKey: "键名",
  ConfValue: "值名",
  ConfComment: "注释",
  // 币种汇率相关
  CurrencyRateItems: "币种汇率",
  RateAdd: "添加新汇率",
  RateFrom: "本币代码",
  RateTo: "兑换币代码",
  RateFromName: "本币名称",
  RateToName: "兑换币名称",
  Rate: "兑换比例",
  RateInverse: "反向兑换比例",
  // rules
  keyMsg: "请输入新配置的键名",
  valMsg: "请输入新配置的值",
  commentMsg: "请输入新配置的注释",
  currencyFromNameMsg: "请输入本币名称",
  currencyToNameMsg: "请输入兑换币名称",
  currencyFromMsg: "请输入本币代码",
  currencyToMsg: "请输入兑换币代码",
  currencyRateMsg: "请输入兑换比例",
  InverseRateMsg: "请输入反向兑换比例",
}