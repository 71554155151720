export default{
  // 航班信息
  AirCode: "Code",
  AirLine: "AirLine",
  AirDep: "DepNode",
  AirLand: "LandNode",
  AirCreateTime: "CreateTime",
  AirExpireTime: "ExpireTime",
  AirExpireTimeSecond: "ExpireTime(seconds)",
  AirDanger: "danger",
  AirInfo: "info",
  AirNormal: "normal",
  AirWarning: "warning",
  AirDetail: "Detail",
  AirPlaceholder: "Search by input airline",
  // 航班详情
  depAirport: "depAirport",
  depTime: "depTime",
  arrAirport: "arrAirport",
  arrTime: "arrTime",
  duration: "duration",
  layoverTime: "layoverTime",
  stopCities: "stopCities",
  stops: "stops",
  // 
  flightInfo: "Flight Info",
  actualCarrier: "actualCarrier",
  actualFlightNo: "actualFlightNo",
  aircraftCode: "aircraftCode",
  availSeatNum: "availSeatNum",
  cabin: "cabin",
  cabinGrade: "cabinGrade",
  fareBasis: "fareBasis",
  adultWeight: "adultWeight",
  adultPiece: "adultPiece",
  childWeight: "childWeight",
  childPiece: "childPiece",
  // 
  priceInfo: "Price Info",
  adultPrice: "adultPrice",
  realAdultPrice: "realAdultPrice",
  childPrice: "childPrice",
  realChildPrice: "realChildPrice",
  adultTax: "adultTax",
  realAdultTax: "realAdultTax",
  childTax: "childTax",
  realChildTax: "realChildTax",
  currency: "currency",
  refresh: "Refresh",
  // 机场信息
  AirportInfo: "Airport Info",
  airportCode: "airportCode",
  airportName: "airportName",
  airCountry: "Country",
  airCountryCode: "countryCode",
  airCity: "City",
  // 航司信息
  airCarriersInfo: "AirCarriers Info",
  carriersName: "CarriersName",
  carriersCode: "CarriersCode",
  // 热门航线
  hotlineInfo: "HotLine Info",
  hotLineAdd: "Add New HotLine",
  fromCode: "fromCode",
  toCode: "toCode",
  isOnline: "isOnline",
  hotLineDelete: "Delete",
  Create: "Create",
  Cancel: "Cancel",
}
