import login from "./zh-cn/login";
import layout from "./zh-cn/layout";
import user from "./zh-cn/user";
import flight from "./zh-cn/flight";
import airconf from "./zh-cn/airconf";

export default {
  ...login,
  ...layout,
  ...user,
  ...flight,
  ...airconf,
};
