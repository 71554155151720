export default [
  {
    path: "/airs",
    name: "airCompany",
    meta: {
      title: "航司信息",
      breadcrumb: ["机票管理", "航司信息"],
    },
    component: () =>
      import(/* webpackChunkName: "airCompany" */ "@/views/ticket/airCompany.vue"),
  },
  {
    path: "/flight",
    name: "flight",
    meta: {
      title: "航线信息",
      breadcrumb: ["机票管理", "航线信息"],
    },
    component: () =>
      import(/* webpackChunkName: "flight" */ "@/views/ticket/flight.vue"),
  },

  {
    path: "/flightDetail",
    name: "flightDetail",
    meta: {
      title: "航线详情",
      breadcrumb: ["机票管理", "航线详情"],
    },
    component: () =>
      import(/* webpackChunkName: "flight" */ "@/views/ticket/flightDetail.vue"),
  },

  {
    path: "/airConfig",
    name: "airConfig",
    meta: {
      title: "配置表",
      breadcrumb: ["机票管理", "配置表"],
    },
    component: () =>
      import(/* webpackChunkName: "flight" */ "@/views/ticket/airConf.vue"),
  },
];
