export default [
  {
    path: "/tasks",
    name: "tasks",
    meta: {
      title: "任务信息",
      breadcrumb: ["任务管理", "任务信息"],
    },
    component: () =>
      import(/* webpackChunkName: "tasks" */ "@/views/tasks/airTask.vue"),
  },
];
