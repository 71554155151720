// 专门出来用户相关的网络请求
import request from "@/utils/request";

/**
 * @description: 用户登录
 */
export function login(data) {
  return request({
    method: "post",
    url: "/user/auth",
    data,
  });
}

/**
 * @description: 用户登出
 */
export function logout() {
  return request({
    method: "delete",
    url: "/user/logout",
  });
}

/**
 * @description: 用户令牌刷新
 */
export function refreshToken(data) {
  return request({
    method: "post",
    url: "/user/refresh",
    data,
  });
}

/**
 * @description: 获取用户信息
 */
export function get_user_info() {
  return request({
    method: "get",
    url: "/user/user_info",
  });
}


/**
 * @description: 修改用户个人信息
 */
export function update_user_info(data) {
  return request({
    method: "post",
    url: "/user/update_user_info",
    data,
  });
}


/**
 * @description: 修改用户密码
 */
export function reset_user_passwd(data) {
  return request({
    method: "post",
    url: "/user/reset_passwd",
    data,
  });
}
